

































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'authGuide',
  props: {},
  computed: {
    ...mapGetters(['userInfo', 'isQuotaLibraryIdentify']),
    notAuth(): boolean {
      return this.isQuotaLibraryIdentify === 0;
    },
    switchAccount(): boolean {
      return this.isQuotaLibraryIdentify === 2;
    },
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 500);
  },
  activated() {
    this.init();
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    userInfo: {
      handler: function () {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      if (!this.userInfo) return (this.visible = false);
      this.visible = this.notAuth || this.switchAccount;
    },
    replace(name = 'portal') {
      let url = `/identity`;
      if (name === 'auth') {
        url = `/sub2/console/accountNumber/enterpriseRealNameAuth`;
      }
      history.pushState(null, '', url);
      // location.replace(url);
    },
    cancel() {
      this.visible = false;
    },
  },
});
